<template>
	<div class="mission-add">
		<!-- <el-breadcrumb separator-class="el-icon-arrow-right" style='margin-bottom:20px'>
      <el-breadcrumb-item >资源库管理</el-breadcrumb-item>
      <el-breadcrumb-item to='/zykgl/mission'>宣教管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{(edit?'编辑':'新增')+'宣教'}}</el-breadcrumb-item>
    </el-breadcrumb>-->
		<el-form :rules="dataRules" label-width="100px" :model="form" ref="form" v-loading="dataListLoading"
			:class="form.type == '' || form.type == 1 || form.type == 2 ? 'form-con-center':''" class="el-form-info">
			<el-form-item label="图表名称" >
				{{form.chartName}}
			</el-form-item>
			<el-form-item label="数据指标">

				<el-table style="width: 850px;" row-key="index" :data="dataList" border align="left">

					<el-table-column min-width="200" prop="questionName" label="问卷名称"></el-table-column>
					<el-table-column min-width="200" label="字段名称">
						<template slot-scope="scope">
							<el-select placeholder="请选择 " disabled v-model="scope.row.cellItem" value-key="id" clearable style="width: 100%;">
								<el-option v-for="item in scope.row.questionTitleList" :key="id" :value="item"
									:label="item.options.label"></el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column min-width="200" label="图表显示名称">
						<template slot-scope="scope">
							{{scope.row.chartDisplayName}}
						</template>

					</el-table-column>
					<el-table-column min-width="200" label="单位">
						<template slot-scope="scope">
							{{scope.row.unitName}}
						</template>
					</el-table-column>
					 
				</el-table>
				 
			</el-form-item>




			<el-form-item label="适用群体"  >
				<span v-if="form.dateType[0]==1">全部</span>
				<span v-if="form.dateType[0]!=1&&form.groupingId!=''">
					{{form.groupingName}}
				</span>
				<span v-if="form.dateType[0]!=1&&form.labelId!=''">
					{{form.labelName}}
				</span>
				
			</el-form-item>
			<el-form-item label="排序权重">
				{{form.orderWeight}}
			</el-form-item>
			<el-form-item label="状态"  >
<span v-if="form.status==1">启用</span>
<span v-if="form.status==3">禁用</span>
				 
			</el-form-item>
			 
		</el-form>

		 
	</div>
</template>
<script>
	import ChoseQuestion from "./components/choseQuestion";
	import Qs from "qs";
	export default {
		components: {
			ChoseQuestion,
		},
		data() {
			return {
				props: {
					multiple: true
				},
				group: [],
				dataList: [

				],
				labelList: [],
				groupingList: [],
				groupingList2: [],
				groupOptions: [{
						value: 1,
						label: '全部',
						children: [{
							value: 2,
							label: '按分组',
							children: [],
						}, {
							value: 3,
							label: '按标签',
							children: [],
						}]
					}


				],
				currentLength: 1,
				firstIndex: 0, //点击新增左侧
				secondIndex: 0, //点击右侧新增白色边框遮住
				thirdIndex: 0, //点击新增筛选条件出现边框遮线
				dialogVisible: false,
				edit: false,
				choseQuestionDialog: true,
				radio: "1",
				imageUrl: "",
				questionObj: "",
				form: {
					status: 1,
					chartName: "",
					orderWeight: "",
					dateType: '',
					labelId: [],
						groupingId: [],
					

				},
				upLoading: false,
				diseasesList: [],
				typeList: [],
				treeList: [{
					selectOutRelation: 1,
					questionName: "",
					analysisList: [{
						list: [{
							require: 1,
							type: "",
							sz: "",
							content: "",
							questionInfo: {},
						}, ],
					}, ],
				}, ],

				dataRules: {
					workName: [{
						required: true,
						message: "宣教名不能为空",
						trigger: "blur"
					}, ],
					workType: [{
						required: true,
						message: "请选择宣教类型",
						trigger: "change"
					}, ],
					// workEnertyType: [{ required: true, message: "请选择病种类型", trigger: "change" }],
					workContent: [{
						required: true,
						message: "宣教内容不能为空",
						trigger: "blur"
					}, ],
					type: [{
						required: true,
						message: "内容形式不能为空",
						trigger: "blur"
					}, ],
				},
				dataListLoading: false,
				secList: [],

				list: [],
			};
		},

		watch: {
			// 'form.workType':function(val){
			//   console.log(val,'val',this.typeList);
			//   if(val){
			//     let name =this.typeList.filter(v=>{
			//     if(v.value==val){
			//       console.log(v);
			//       return v
			//     }
			//   })[0].name
			//   this.getDictList2(name)
			//   }
			// }
		},

		async mounted() {
			let query = this.$route.query;
			await this.getTypeList();
			await this.getList();
			 
			await this.getDiseasesList();
			await this.getGroupList()
			await this.getGroupList2()
			this.form = JSON.parse( sessionStorage.chartInfo)
			this.form.dateType = this.form.dateType.split(',')
			this.form.groupingId = this.form.groupingId.split(',')
			this.form.labelId = this.form.labelId.split(',')
			if(this.form.groupingId.length>0){
				for (let item of this.form.groupingId) {
					this.group.push([1,2,item])
				}
			}
			if(this.form.labelId.length>0){
				for (let item of this.form.labelId) {
					this.group.push([1,3,item])
				}
			}
			
			console.log(this.group)
			for (let item of this.form.dataIndicators) {
				item.questionName = item.infoName
				
				let obj = {
					cellId :item.cellId,
					chartDisplayName : item.infoName,
					questionName :  item.infoName,
					questionNameId :  item.infoId,
					type : item.type
				}
				this.setQuestionId(obj,item)
				
				
				
			}
			console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!",this.form)
		},

		methods: {
			async getGroupList() {
				const user = JSON.parse(localStorage.getItem("userInfo"));
				let teamsId = "";
				let enertyId = "";
				if (localStorage.currentTeam) {
					teamsId = JSON.parse(localStorage.currentTeam).id;
					enertyId = JSON.parse(localStorage.currentTeam).entityId;
				}
				let params = {
				
					
					stringParam11: teamsId,
					intParam1: 1000,
					intParam2: 1,
					intParam3: 1,
				};
				const {
					data: res
				} = await this.$httpAes({
					url: this.$http.adornUrl("/mbglLabel/list"),
					method: "post",
					data: params,
				});
				if (res.status) {
					res.data.forEach(item => {
						item.label = item.labelName
						item.value = item.id
					})
					this.groupingList = res.data


					this.groupOptions[0].children[0].children = res.data;
				}
			},
			async getGroupList2() {
				const user = JSON.parse(localStorage.getItem("userInfo"));
				let teamsId = "";
				let enertyId = "";
				if (localStorage.currentTeam) {
					teamsId = JSON.parse(localStorage.currentTeam).id;
					enertyId = JSON.parse(localStorage.currentTeam).entityId;
				}
				let params = {
					
					stringParam11: teamsId,
					intParam1: 1000,
					intParam2: 1,
					intParam3:2,
				};
				const {
					data: res
				} = await this.$httpAes({
					url: this.$http.adornUrl("/mbglLabel/list"),
					method: "post",
					data: params,
				});
				if (res.status) {

					res.data.forEach(item => {
						item.label = item.labelName
						item.value = item.id
					})
					this.groupingList2 = res.data
					this.groupOptions[0].children[1].children = res.data;
				}
			},
			setQuestionId(value,item) {
				console.log('setQuestionIdsetQuestionId', value)
				value.chartDisplayName = ''
				value.unitName = ''
				value.cellId = ''
				let _this = this;
				_this.questionObj = value;
				_this.getQustionTitleList();
				_this.$set(
					_this.treeList[_this.firstIndex],
					"questionName",
					_this.questionObj.questionName
				);
				_this.$set(
					_this.treeList[_this.firstIndex],
					"questionNameId",
					_this.questionObj.questionNameId
				);
				// _this.treeList[_this.firstIndex].analysisList[0].list = [
				//   {
				//     require: 1,
				//     type: "",
				//     sz: "",
				//     content: "",
				//     questionInfo: {},
				//   },
				// ];
				let arr = [];
				_this.treeList.forEach((ele) => {

					if (ele.questionNameId) {
						// if (ele.questionNameId == value.questionNameId) {
						//   _this.$message.error('同一个问卷不能多次选择')
						// }
						arr.push(ele);
					}
				});

				// _this.dataList = [_this.dataList[0], ...JSON.parse(JSON.stringify(arr))];
				if(item){
					
					value.cellItem   = {
						
						id: item.cellId,
						options: {
							label: item.cellName,
							id: item.cellId,
						
						}
					}
					value.cellId=item.cellId
						value.unitName=item.unitName
						value.chartDisplayName=item.chartDisplayName
				}
				_this.dataList.push(value);
				console.log("123", this.dataList);
				_this.dataList.forEach((ele, index) => {
					console.log(ele)

					if (ele.questionNameId && ele.questionNameId != 1 && ele.type == '1') {
						let params = {
							id: ele.questionNameId,
							stringParam1:ele.questionNameId,
						};

						if (params.id) {
							_this
								.$httpAes({
            url: _this.$http.adornUrl("/vfTableInfo/info"),
									method: "post",
									data: params,
								})
								.then(({
									data
								}) => {
									if (data.status) {
										if (data.data && data.data.tableContent) {
											let widgetList = JSON.parse(
												data.data.tableContent
											).widgetList;
											let arr = [];
											if (widgetList.length) {
												widgetList.forEach((ele) => {
													if (
														ele.type == "input" ||
														ele.type == "slider" ||
														ele.type == "radio" ||
														ele.type == "checkbox" ||
														ele.type == "number" ||
														ele.type == "textarea" ||
														ele.type == "time" ||
														ele.type == "time-range" ||
														ele.type == "date" ||
														ele.type == "date-range" ||
														ele.type == "select" ||
														ele.type == "rate" ||
														ele.type == "rich-editor"
													) {
														arr.push(ele);
													}
												});
											}
											this.$set(ele, "questionTitleList", arr);
										}
										// ele.questionTitleList = widgetList;
									}
								});
						}
					} else {
						console.log('22222222222222222222222222222222222')
						if (ele.questionName == '血压') {
							this.$set(ele, "questionTitleList", [{
								id: '收缩压',
								options: {
									label: '收缩压',
									id: '收缩压',

								}
							}, {
								id: '舒张压',
								options: {
									label: '舒张压',
									id: '舒张压',

								}
							}]);
						} else {
							this.$set(ele, "questionTitleList", [{
								id: ele.questionNameId,
								options: {
									label: ele.questionName,
									id: ele.questionNameId

								}
							}]);
						}
					}

				});
			},
			async getList() {
				let _this = this;
				_this.loading = true;
				const {
					data: res
				} = await this.$httpAes({
        url: this.$http.adornUrl("/mbglLabelIndex/list"),
					method: "post",
					data: {
						id: _this.id
					},
				});

				if (res.status && res.data && res.data.length) {
					_this.loading = false;
					let info = res.data[0];
					this.info = res.data[0];
					_this.sex = info.sex;
					_this.ageLowerLimit = info.ageLowerLimit;
					_this.ageUpperLimit = info.ageUpperLimit;
					_this.mainDiagnosis = info.mainDiagnosis;
					let list = info.otherDiagnosis ? info.otherDiagnosis.split(",") : "";
					if (list.length) {
						_this.otherDiagnosis = list.map((item) => {
							return item * 1;
						});
					}
					if (info.diagnosisEndTime) {
						_this.dateTime = [info.diagnosisStartTime, info.diagnosisEndTime];
					}
					_this.cityInfo = {
						province: info.patientProvince,
						provinceName: info.provinceName,
						city: info.patientCity,
						cityName: info.cityName,
						block: info.patientCounty,
						blockName: info.countyName,
					};
					if (info.content) {
						_this.treeList = [];
						let content = JSON.parse(info.content);
						content.forEach((ele, index) => {
							let obj = {
								questionNameId: ele.infoId,
								questionName: ele.infoName,
								selectOutRelation: ele.require,
							};
							obj.analysisList = [{
								list: [],
							}, ];
							obj.analysisList[0].list = ele.data;
							_this.treeList.push(obj);
							if (_this.treeList.length) {
								_this.questionObj = {
									questionNameId: _this.treeList[0].questionNameId,
									questionName: _this.treeList[0].questionName,
								};
								_this.getQustionTitleList();
								_this.analysisList = _this.treeList[0].analysisList;
								console.log("456", _this.analysisList);
								if (_this.analysisList.length) {
									_this.analysisList[0].list.forEach((element) => {
										if (
											element.content &&
											(element.questionType == "time-range" ||
												element.questionType == "date-range")
										) {
											element.content = element.content.split(",");
										}
									});
								}
							}
						});
					} else {}
				} else {
					_this.loading = false;
				}

				if (_this.treeList.length) {
					let arr = [];
					_this.treeList.forEach((ele) => {
						if (ele.questionNameId) {
							arr.push(ele);
						}
					});

					// _this.dataList = [
					//   _this.dataList[0],
					//   ...JSON.parse(JSON.stringify(arr)),
					// ];

					// _this.dataList.forEach((ele, index) => {
					//   this.$set(ele,'currentChoseList',[])
					//   this.$set(ele,'choseList',[])
					//   // ele.currentChoseList = [];
					//   // ele.choseList = [];
					//   if (ele.questionNameId) {
					//     let params = {
					//       id: ele.questionNameId,
					//     };
					//     if (params.id) {
					//       _this
					//         .$http({
					//           url: _this.$http.adornUrl("/vfTableInfo/info"),
					//           method: "get",
					//           params: params,
					//         })
					//         .then(({ data }) => {
					//           if (data.status && data.data && data.data.tableContent) {
					//             let widgetList = JSON.parse(
					//               data.data.tableContent
					//             ).widgetList;
					//             let arr = [];
					//             if (widgetList.length) {
					//               widgetList.forEach((ele) => {
					//                 if (
					//                   ele.type == "input" ||
					//                   ele.type == "slider" ||
					//                   ele.type == "radio" ||
					//                   ele.type == "checkbox" ||
					//                   ele.type == "number" ||
					//                   ele.type == "textarea" ||
					//                   ele.type == "time" ||
					//                   ele.type == "time-range" ||
					//                   ele.type == "date" ||
					//                   ele.type == "date-range" ||
					//                   ele.type == "select" ||
					//                   ele.type == "rate" ||
					//                   ele.type == "rich-editor"
					//                 ) {
					//                   arr.push(ele);
					//                 }
					//               });
					//             }
					//             this.$set(ele, "questionTitleList", arr);
					//             // ele.questionTitleList = widgetList;
					//           }
					//         });
					//       if (_this.info.exportedContent) {
					//         let exportedContent = JSON.parse(_this.info.exportedContent);
					//         exportedContent.forEach((chr) => {
					//           if (ele.questionNameId == chr.questionNameId) {
					//             if (chr.currentChoseList && chr.currentChoseList.length) {
					//               // this.$set(ele, "currentChoseList", chr.currentChoseList);
					//               // this.$set(ele, "choseList", chr.choseList);
					//               ele.currentChoseList = chr.currentChoseList;
					//               ele.choseList = chr.choseList;
					//             }
					//           }
					//         });
					//       }
					//     } else {
					//     }
					//   }
					// });

				}
			},
			getQustionTitleList(item, $index) {
				let _this = this;
				let params = {
					// pageSize: 10000,
					// pageNo: 1,
					// libraryId: _this.questionObj.questionNameId,
					id: _this.questionObj.questionNameId,
						stringParam1:_this.questionObj.questionNameId,
				};
				if (item && item.questionId) {
					params.libraryId = item.questionId;
				}
				if (params.id && params.id != 1) {
					_this
						.$httpAes({

            url: _this.$http.adornUrl("/vfTableInfo/info"),
							method: "post",
							data: params,
						})
						.then(({
							data
						}) => {
							if (data.status) {
								let widgetList = JSON.parse(data.data.tableContent).widgetList;
								let arr = [];
								if (widgetList.length) {
									widgetList.forEach((ele) => {
										if (
											ele.type == "input" ||
											ele.type == "slider" ||
											ele.type == "radio" ||
											ele.type == "checkbox" ||
											ele.type == "number" ||
											ele.type == "textarea" ||
											ele.type == "time" ||
											ele.type == "time-range" ||
											ele.type == "date" ||
											ele.type == "date-range" ||
											ele.type == "select" ||
											ele.type == "rate" ||
											ele.type == "rich-editor"
										) {
											arr.push(ele);
										}
									});
								}
								_this.questionTitleList = arr;
							}
						});
				} else {
					_this.questionTitleList = [];
				}
			},
			showchoseQuestionDialog() {
				let _this = this;
				_this.choseQuestionDialog = true;
				_this.$nextTick(() => {
					_this.$refs.choseQuestion.init(this.questionObj);
				});
			},
			handleChange(arr) {
				console.log(arr, "v");
				this.form.labelId = []
					this.form.groupingId = []
				if (arr.length == (this.groupingList.length + this.groupingList2.length)) {
					this.form.dateType = [1]
				} else {

					let flag1 = false
					let flag2 = false
					for (let itemArr of arr) {
					
						if (itemArr[1] == 2) {
							flag1 = true
	this.form.groupingId.push(itemArr[2])
						}
						if (itemArr[1] == 3) {
							flag2 = true
							this.form.labelId.push(itemArr[2])
						}
					}
					this.form.dateType = []
					if (flag1) {
						this.form.dateType.push(2)
					}
					if (flag2) {
						this.form.dateType.push(3)
					}

				}


				
			},
			getOther(arr) {
				console.log(arr, "val gteOther");

				var that = this
				arr.forEach((item) => {
					let obj = {
						orderNum: that.list.length + 1,
						contentType: that.contentType,
						contentTypeName: that.contentTypeArr[that.contentType - 1],
						relationName: item.tableName,
						relationId: item.id,
						isClick: '',
					}
					let flag = false
					for (let obj of that.list) {
						if (obj.relationId == item.id) {

							flag = true
						}
					}
					if (flag) {
						this.$message.warning('内容选择重复')

					} else {
						that.list.push(obj)
						that.relationObj = ''
						that.visible = false
					}

				})
			},
			// 获取二级分类
			getDictList2(dict) {
				let teamsId = "";
				if (localStorage.currentTeam) {
					teamsId = JSON.parse(localStorage.currentTeam).id;
				}
				let _this = this;
				_this
					.$http({
						url: _this.$http.adornUrl("/dict/getSysDicts?type=" + dict),
						method: "get",
						params: {
							pageSize: 999,
							teamsId,
						},
					})
					.then(({
						data
					}) => {
						if (data.status) {
							_this.secList = data.data;
						}
					});
			},
			delTb(row,index) {
			this.dataList.splice(index,1)
			},
			previewVideo() {
				if (!this.form.workContent) {
					this.$message.error("请上传视频！");
					return;
				}
				this.dialogVisible = true;
				// this.dataListLoading = true
				setTimeout(() => {
					this.initVideo();
				}, 0);
			},
			initVideo() {
				let that = this;
				let width = this.getParams("width");
				let height = this.getParams("height");
				// console.log(width);
				var player = new TcPlayer("abc", {
					m3u8: "https://aibiboat.oss-cn-shanghai.aliyuncs.com/" +
						this.form.workContent, //请替换成实际可用的播放地址
					autoplay: false, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
					poster: {
						style: "stretch",
						src: that.imageUrl
					},
					width: width || "100%",
					height: height || "100%",
					//playbackRates: [0.5,1,1.2,1.5,2], // 设置变速播放倍率选项，仅 HTML5 播放模式有效
					// controls:'system', // default 显示默认控件，none 不显示控件，system 移动端显示系统控件 备注：如果需要在移动端使用系统全屏，就需要设置为system。默认全屏方案是使用 Fullscreen API + 伪全屏的方式例子
					pausePosterEnabled: true,
					volume: 0.5,
					systemFullscreen: true,
					x5_orientation: 0,
				});
				document.getElementsByClassName("vcp-error-tips")[0].style.fontSize =
					"14px"; // 错误码提示语字体大小，因引入了rem，默认字体大小为100px.8
			},
			getParams(name) {
				var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
				var r = window.location.search.substr(1).match(reg);
				if (r != null) {
					return decodeURIComponent(r[2]);
				}
				return null;
			},
			handleAvatarSuccess(res, file) {
				this.upLoading = false;
				console.log(res, "success res");
				// this.form.workContent = res.result.filePath;
				this.form.workContent = res.result.path;
				this.form.mvUrl = res.result.path;
				this.imageUrl =
					"https://aibiboat.oss-cn-shanghai.aliyuncs.com/" +
					this.form.mvUrl +
					"?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto";
			},
			handleAvatarSuccess2(res, file) {
				this.upLoading = false;

				console.log(res, "success res");
				// this.form.workContent = res.result.filePath;
				this.form.workContent = res.result.path;

				this.form.mvUrl = res.result.path;
				// this.imageUrl =
				//   'https://aibiboat.oss-cn-shanghai.aliyuncs.com/' +
				//   this.form.mvUrl +
				//   "?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto";
			},


			back() {
				this.$router.back();
			},
			async init(id) {
				const {
					data: res
				} = await this.$httpAes({
					url: this.$http.adornUrl("/missionaryWork/getMissionaryWork"),
					method: "post",
					data: {
						stringParam1: id
					},
				});
				if (res.status) {
					this.form = res.data;
					this.dataListLoading = false;
					// this.form.workType = [res.data.workType,res.data.workTypeSec,res.data.workTypeThree,res.data.workTypeFour,res.data.workTypeFive]
					let arr = [];
					if (res.data.workType) {
						arr.push(res.data.workType);
					}
					if (res.data.workTypeSec) {
						arr.push(res.data.workTypeSec);
					}
					if (res.data.workTypeThree) {
						arr.push(res.data.workTypeThree);
					}
					if (res.data.workTypeFour) {
						arr.push(res.data.workTypeFour);
					}
					if (res.data.workTypeFive) {
						arr.push(res.data.workTypeFive);
					}
					this.form.workType = arr;
					if (this.form.workContentUrl) {
						this.radio = "2";
						this.form.mvUrl =
							"https://aibiboat.oss-cn-shanghai.aliyuncs.com/" +
							res.data.workContent;
						this.imageUrl =
							"https://aibiboat.oss-cn-shanghai.aliyuncs.com/" +
							res.data.workContentUrl +
							"?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto";
					}
				}
			},
			async getDiseasesList() {
				const {
					data: res
				} = await this.$httpAes({
					url: this.$http.adornUrl("/dict/list"),
					method: "post",
					data: {
						stringParam1: "基础病种",
					},
				});
				// console.log(res,'res')
				this.diseasesList = res.data;
			},
			async getTypeList() {
				let teamsId = "";
				if (localStorage.currentTeam) {
					teamsId = JSON.parse(localStorage.currentTeam).id;
				}
				const {
					data: res
				} = await this.$http({
					url: this.$http.adornUrl("/dict/getSysDicts"),
					method: "get",
					params: {
						type: "宣教分类",
						pageSize: 999,
						teamsId,
					},
				});
				// console.log(res,'res')
				this.typeList = res.data;
				for (var i = 0; i < res.data.length; i++) {
					res.data[i].isEdit = false;
					res.data[i].children = res.data[i].map.list;
					// console.log(res.data[i].children,'res.data[i].children');
					for (var k = 0; k < res.data[i].children.length; k++) {
						if (
							res.data[i].children[k].map &&
							res.data[i].children[k].map.list.length
						) {
							res.data[i].children[k].children = res.data[i].children[k].map.list;
							for (var j = 0; j < res.data[i].children[k].children.length; j++) {
								if (
									res.data[i].children[k].children[j].map &&
									res.data[i].children[k].children[j].map.list.length
								) {
									res.data[i].children[k].children[j].children =
										res.data[i].children[k].children[j].map.list;
									for (
										var m = 0; m < res.data[i].children[k].children[j].children.length; m++
									) {
										if (
											res.data[i].children[k].children[j].children[m].map &&
											res.data[i].children[k].children[j].children[m].map.list
											.length
										) {
											res.data[i].children[k].children[j].children[m].children =
												res.data[i].children[k].children[j].children[m].map.list;
										} else {
											res.data[i].children[k].children[j].children[
												m
											].children = undefined;
										}
									}
								} else {
									res.data[i].children[k].children[j].children = undefined;
								}
							}
						} else {
							res.data[i].children[k].children = undefined;
						}
					}
				}
				this.options = res.data;
			},
			async submit() {
			
				let params = {
					id:this.form.id,
					chartName: this.form.chartName,
					orderWeight: this.form.orderWeight,
					teamsId: JSON.parse(localStorage.currentTeam).id,
					status:this.form.status,
					dateType:this.form.dateType.join(','),
					labelId:this.form.labelId.join(','),
						groupingId:this.form.groupingId.join(','),
					dataIndicators:[]
				}
for (let item of this.dataList) {
	console.log(item)
	let obj = {
		  "cellId": item.cellItem.id,
		  cellName:item.cellItem.options.label,
		  type:item.type,
		  infoId:item.questionNameId,
		  infoName:item.questionName,
		  chartDisplayName:item.chartDisplayName,
		  unitName:item.unitName,
		  status:1,
		  teamsId: JSON.parse(localStorage.currentTeam).id,
		  
		  
	}
	params.dataIndicators.push(obj)
}
				const {
					data: res
				} = await this.$http({
					url: this.$http.adornUrl(`/indicatorChart/add`),
					method: "post",
					headers: {
					  "Content-Type": "application/json",
					  token: this.$cookie.get("token")
					},
					data: JSON.stringify(params),
				});
				console.log(res);
				if (res.status) {
  this.$message({
                message: "修改成功",
                type: "success",
                duration: 1500,
                onClose: () => {
                this.$router.go(-1)
                },
              });
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.mission-add {
		.form-con-center {
			align-items: flex-start;
		}

		.tianjia {
			cursor: pointer;
			margin-top: 20px;
			display: flex;
			align-items: center;
			font-family: PingFangSC, PingFang SC;
			font-weight: 400;
			font-size: 14px;
			color: #009CFF;
			line-height: 20px;
			text-align: left;
			font-style: normal;

		}
	}
</style>